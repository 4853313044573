import clsx from "clsx";
import React, { useState } from "react";

import "../scss/Insight.scoped.scss";

function Insight(props) {
  const { insight, index, update } = props;

  const [aggregatedVisible, setAggregatedVisible] = useState(false);

  if (insight === undefined) {
    return;
  }

  if (insight.type === "unconfigured") {
    return (
      <div class="organization-explainables-insight">
        <div
          class="organization-explainables-description"
          dangerouslySetInnerHTML={{ __html: insight.description }}
        ></div>
      </div>
    );
  }

  if (insight.type === "aggregated") {
    return (
      <div class="organization-explainables-aggregated-insight">
        <div
          className={clsx(
            "organization-explainables-insight",
            insight.grade === "good" && "organization-explainables-good",
            insight.grade === "bad" && "organization-explainables-bad",
            insight.grade === "warning" && "organization-explainables-warning",
            insight.grade === "error" && "organization-explainables-error"
          )}
        >
          <i class="bi bi-left bi-layers"></i>
          {update && (
            <span class="organization-explainables-new" data-testid="new">
              NEW
            </span>
          )}
          <div
            data-testid="description"
            class="organization-explainables-description"
            dangerouslySetInnerHTML={{ __html: insight.summary }}
          ></div>
          <i
            role="button"
            data-testid="expand"
            tabIndex={index}
            className={clsx(
              "bi",
              "bi-right",
              !aggregatedVisible && "bi-caret-down",
              aggregatedVisible && "bi-caret-up"
            )}
            onClick={(event) => {
              setAggregatedVisible(!aggregatedVisible);
            }}
          ></i>
        </div>
        {aggregatedVisible && insight?.payload?.aggregated_items && (
          <div
            class="organization-explainables-aggregated"
            data-testid="container"
          >
            {insight.payload.aggregated_items.map((recursed_insight) => (
              <Insight insight={recursed_insight} />
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "organization-explainables-insight",
        insight.grade === "good" && "organization-explainables-good",
        insight.grade === "bad" && "organization-explainables-bad",
        insight.grade === "warning" && "organization-explainables-warning",
        insight.grade === "error" && "organization-explainables-error"
      )}
      data-testid="insight"
    >
      <i className={clsx("bi", "bi-left", insight.icon)}></i>
      {update && (
        <span class="organization-explainables-new" data-testid="new">
          NEW
        </span>
      )}
      <div
        class="organization-explainables-description"
        data-testid="description"
        dangerouslySetInnerHTML={{ __html: insight.description }}
      ></div>
    </div>
  );
}

export default Insight;
