import clsx from "clsx";
import { capitalize, isEqual, snakeCase } from "lodash";
import React from "react";

import Insight from "./Insight";

import insights_config, {
  unconfiguredInsightTemplate,
} from "../../../../const/insights";

import "../scss/App.scoped.scss";

const insightsSections = ["people", "product", "market", "funding"];
const insightsSectionsIcons = {
  people: "bi-person-bounding-box",
  product: "bi-ui-checks-grid",
  market: "bi-shop-window",
  funding: "bi-wallet",
};

function insightsComparator(a, b) {
  if (a.grade === b.grade) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  } else if (
    a.grade === "good" &&
    (b.grade === "warning" || b.grade === "bad")
  ) {
    return -1;
  } else if (a.grade === "warning" && b.grade === "bad") {
    return -1;
  } else {
    return 1;
  }
}

const aggregateInsights = (insights) => {
  let people_insights = [];
  let product_insights = [];
  let market_insights = [];
  let funding_insights = [];

  if (insights === undefined) {
    return {
      people_insights,
      product_insights,
      market_insights,
      funding_insights,
    };
  }

  const results = insightsSections.map((insights_group) => {
    const insights_config_names = Object.entries(insights_config.insights)
      .filter(([name, insight], i) => insight.section === insights_group)
      .map((insight) => insight[0]);

    const insights_config_template = Object.entries(insights_config.insights)
      .filter(([name, insight], i) => insight.section === insights_group)
      .map((insight) => insight[1]);

    let unconfiguredAdded = [];

    let insights_list = insights.reduce(
      (acc, { insight_name, payload, grade, update }) => {
        if (insights_config_names.includes(insight_name)) {
          const templateIndex = insights_config_names.indexOf(insight_name);
          const template = insights_config_template[templateIndex];

          let description = "";
          try {
            description = template.description(payload);
          } catch (e) {
            console.error(e);
            description = `${insight_name} - ${e.message}`;
            grade = "error";
          }

          let summary = "";
          try {
            summary = template.summary(payload);
          } catch (e) {
            console.error(e);
            description = `${insight_name} - ${e.message}`;
            grade = "error";
          }

          acc.push({
            name: insight_name,
            description: description,
            summary: summary,
            update: update ? true : false,
            grade,
            payload,
            icon: insights_config.insights[insight_name].icon,
            type: "derived",
          });
        } else {
          if (
            !Object.keys(insights_config.insights).includes(insight_name) &&
            !unconfiguredAdded.includes(insight_name) &&
            insights_group === "market"
          ) {
            unconfiguredAdded.push(insight_name);
            acc.push({
              name: insight_name,
              description: unconfiguredInsightTemplate({
                i_name: insight_name,
              }),
              type: "unconfigured",
            });
          }
        }
        return acc;
      },
      []
    );

    const aggregations = Object.entries(insights_config.aggregations)
      .filter(([key, aggregation]) => aggregation.section === insights_group)
      .reduce((aggregations, [key, aggregation], index) => {
        aggregations[key] = insights_list
          .filter((insight) => insight.name === key)
          .filter((insight) => insight.type !== "unconfigured")
          .reduce((acc, value) => {
            aggregation.counters.forEach((counter) => {
              if (!acc[counter]) acc[counter] = new Set();
            });
            if (!acc.aggregated_items) acc.aggregated_items = [];
            aggregation.counters.forEach((counter) => {
              acc[counter].add(value.payload[counter]);
            });
            acc.aggregated_items.push(value);
            return acc;
          }, {});

        return aggregations;
      }, {});

    const insights_aggregated = Object.entries(aggregations)
      .filter(([key, value]) => !isEqual(value, {}))
      .filter(([key, value]) => {
        const counters = insights_config.aggregations[key].counters;
        return counters.some((counter) => {
          return value[counter].size > 1;
        });
      })
      .map(([key, value]) => {
        const counters = insights_config.aggregations[key].counters;
        counters.forEach((counter) => {
          value[counter] = value[counter].size;
        });

        let summary = "";
        let grade = insights_config.aggregations[key].grade;
        try {
          summary = insights_config.aggregations[key].summary(value);
        } catch (e) {
          console.error(e);
          summary = `aggregated_insight - ${e.message}`;
          grade = "error";
        }

        return {
          name: key,
          payload: value,
          type: "aggregated",
          grade,
          summary,
        };
      });

    const insights_aggregated_keys = insights_aggregated.map(
      (insight) => insight.name
    );

    insights_list = [
      ...insights_list.filter(
        (insight) => !insights_aggregated_keys.includes(insight.name)
      ),
      ...insights_aggregated,
    ];

    return insights_list.sort(insightsComparator);
  });

  return {
    people_insights: results[0],
    product_insights: results[1],
    market_insights: results[2],
    funding_insights: results[3],
  };
};

function App(props) {
  const { compressed = true, organization } = props;

  const insights =
    organization.ml_features?.insights_extractor?.highlights?.insights.reduce(
      (acc, insight) => {
        if (
          organization.ml_updated_features?.insights_extractor?.highlights?.insights.every(
            (insight_updated) => !isEqual(insight_updated, insight)
          ) ||
          organization.ml_updated_features?.insights_extractor?.highlights
            ?.insights === undefined
        ) {
          acc.push(insight);
        }
        return acc;
      },
      []
    );

  const {
    people_insights,
    product_insights,
    market_insights,
    funding_insights,
  } = aggregateInsights(insights);

  const {
    people_insights: people_updated_insights,
    product_insights: product_updated_insights,
    market_insights: market_updated_insights,
    funding_insights: funding_updated_insights,
  } = aggregateInsights(
    organization.ml_updated_features?.insights_extractor?.highlights?.insights
  );

  if (
    !people_insights.length &&
    !product_insights.length &&
    !market_insights.length &&
    !funding_insights.length &&
    !people_updated_insights.length &&
    !product_updated_insights.length &&
    !market_updated_insights.length &&
    !funding_updated_insights.length
  ) {
    return (
      <div
        className={clsx(
          "organization-explainables",
          compressed && "compressed"
        )}
      >
        <div className={clsx("section-title", compressed && "compressed")}>
          Insights
        </div>
        <div class="no-insights">No Insights found.</div>
      </div>
    );
  }

  return (
    <div
      className={clsx("organization-explainables", compressed && "compressed")}
    >
      <div className={clsx("section-title", compressed && "compressed")}>
        Insights
      </div>
      <div class="organization-explainables-sections">
        {insightsSections.map((section) => (
          <div class="organization-explainables-section">
            <div class="data-header">
              <i className={clsx("bi", insightsSectionsIcons[section])}></i>{" "}
              {capitalize(section)}
            </div>
            {eval(snakeCase(section + "_updated_insights")).map(
              (insight, i) => (
                <Insight insight={insight} index={i} update={true} />
              )
            )}
            {eval(snakeCase(section + "_insights")).map((insight, i) => (
              <Insight insight={insight} index={i} update={false} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
